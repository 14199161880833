import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <section className="main">
          <div className="container">
            <div className="tagline">
              <h1>&nbsp;</h1>
            </div>
            <div className="d-flex flex-row">
              <div className="right">
                <h2 className="country_deduction your_location_title">
                  &nbsp;
                </h2>
                <ul className="country_deduction country_deduction_list">
                  &nbsp;
                </ul>
                <h6 className="select_country_headline">&nbsp;</h6>
                <h6 className="select_country_title">&nbsp;</h6>
                <div class="scroll-country bottom-faded">
                  <ul className="country_list"></ul>
                </div>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div id="popup_main">
            <div class="container">
              <div class="main-content">
                <a href="#" class="close" title="close">close</a>
                <div class="mob-content hide-desk">
                  <img class="logo" src="images/johnson-logo.png" width="192.38" height="67.65" />
                  <h1 class="title-one">We are getting ready to launch.</h1>
                </div>
                <div class="desk-content hide-mob">
                  <div class="left">
                    <img class="logo" src="images/johnson-logo.png" width="262.76px" height="92.43px" />
                    <h1 class="title-one">We are getting ready to launch.</h1>
                  </div>
                  <div class="right">
                    <img class="prod" src="images/prod.png" />
                  </div>
                </div>

                <p>Our new eco refills website hub is coming soon and we are currently working to give you the best experience.</p>
                <h2 class="title-two">Please check back soon!</h2>
              </div>
            </div>
          </div>
        </section>

        <Footer></Footer>
        <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.6/umd/popper.min.js"
          integrity="sha384-wHAiFfRlMFy6i5SRaxvfOCifBUQy1xHdJ/yoi7FRNXMRBu5WHdZYu1hA6ZOblgut"
          crossOrigin="anonymous"
        />
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js"
          integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k"
          crossOrigin="anonymous"
        />
        <script src="/js/scripts.js"></script>
      </div>
    );
  }
}

export default Page;
