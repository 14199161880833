import React from "react";

class Header extends React.Component {
  render() {
    return (
      <header className="header container">
        <a href="/"><img src="/images/Johnsons_Logo.svg" width="216"/></a>
      </header>
    );
  }
}

export default Header;
